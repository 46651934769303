import React, { useState, useEffect } from 'react';
import data from './data.json';
import { Helmet } from 'react-helmet'; // Add this import
import seedrandom from 'seedrandom';

function Game() {
    const [gameData, setGameData] = useState([]);
    const [difficulty, setDifficulty] = useState('Medium');
    const [unplayedGames, setUnplayedGames] = useState([]);
    const [currentGame, setCurrentGame] = useState(null);
    const [currentGuess, setCurrentGuess] = useState([]);
    const [foundGroups, setFoundGroups] = useState({});
    const [attempts, setAttempts] = useState(4);
    const [message, setMessage] = useState('');
    const [animation, setAnimation] = useState(null);
    const [animationWords, setAnimationWords] = useState([]);
    const [gameOver, setGameOver] = useState(false);
    const [gameId, setGameId] = useState(null); // Add this line
    const [timeElapsed, setTimeElapsed] = useState(0);
    const [selectionStreak, setSelectionStreak] = useState([]);
    const [isArchive, setIsArchive] = useState(false);

    const levelColors = ['#fbd400', '#b5e352', '#729eeb', '#bc70c4'];
    const levelEmojis = ["🔵", "🟢", "🟡", "🔴", "🟣"]; // Choose your own emojis

    useEffect(() => {
        setTimeElapsed(0);  // Reset the timer when starting a new game
  
        const timer = setInterval(() => {
            setTimeElapsed(timeElapsed => timeElapsed + 1);
        }, 1000);
  
        return () => clearInterval(timer);
    }, [gameId]);

    function generateStartingGroups(game) {
      if (!game || !game.categories || !Array.isArray(game.categories)) {
          console.error('Expected categories to be an array, but received:', game.categories);
          return [[], [], [], []];  // Return empty starting groups if the input is invalid
      }
  
      // Initialize the 4x4 grid as an array of 4 rows
      let newStartingGroups = [[], [], [], []];
      
      // Collect all cards from all categories
      let allCards = [];
      game.categories.forEach(category => {
          if (!category.cards || !Array.isArray(category.cards)) {
              console.error('Expected cards to be an array, but received:', category.cards);
              return; // Skip categories without valid cards
          }
          allCards = allCards.concat(category.cards);  // Collect all cards into one array
      });
  
      // Sort all cards by their position
      allCards.sort((a, b) => a.position - b.position);
  
      // Distribute the sorted cards into rows (4 per row)
      allCards.forEach((card, index) => {
          let rowIndex = Math.floor(index / 4);  // Calculate the row index
          if (rowIndex >= 0 && rowIndex < 4) {
              newStartingGroups[rowIndex].push(card.content);  // Push the card content into the appropriate row
          }
      });
  
      return newStartingGroups;
  }
  
  
  

  function mixGroups(gameId, difficulty) {
    const rng = seedrandom(gameId);
    let newGame = { categories: [], startingGroups: [[], [], [], []] };
    let usedWords = new Set();

    // Define difficulty levels using the position of the category object in the categories array
    let levels;
    switch (difficulty) {
        case "Easy":
            levels = [0, 1];  // Use the first two categories
            break;
        case "Hard":
            levels = [2, 3];  // Use the last two categories
            break;
        default: // Medium
            levels = [0, 1, 2, 3];  // Use all categories
            break;
    }

    // Shuffle levels for "Medium" difficulty
    if (difficulty === "Medium") {
        levels = levels.sort(() => 0.5 - rng());
    }

    // Duplicate levels for "Easy" and "Hard" to ensure 4 groups are selected
    if (difficulty === "Easy" || difficulty === "Hard") {
        levels = [...levels, ...levels];  // Duplicate levels to fill 4 groups
    }

    // Loop through the selected levels (4 iterations)
    for (let i = 0; i < 4; i++) {
        let categoryIndex = levels[i];
        let selectedCategory;

        // Keep trying until a suitable category is found
        do {
            // Select a random game from the data
            let randomId = Math.floor(rng() * data.length);
            let randomGame = data[randomId];

            if (!randomGame || !randomGame.categories || !randomGame.categories[categoryIndex]) {
                continue;  // Skip if the category does not exist
            }

            // Pick the category at the level determined by the difficulty
            selectedCategory = randomGame.categories[categoryIndex];

            // Ensure the category has valid cards and none of the cards have been used
        } while (!selectedCategory || !selectedCategory.cards || selectedCategory.cards.some(card => usedWords.has(card.content)));

        // Assign the selected category to the new game
        newGame.categories.push(selectedCategory);

        // Shuffle members into startingGroups randomly
        selectedCategory.cards.forEach(card => {
            if (!usedWords.has(card.content)) {
                usedWords.add(card.content);

                let insertionRow;
                do {
                    insertionRow = Math.floor(rng() * 4);  // Randomly choose one of the 4 rows
                } while (newGame.startingGroups[insertionRow].length === 4);  // Ensure the row has space for 4 cards
                newGame.startingGroups[insertionRow].push(card.content);  // Push the card content into the random row
            }
        });
    }

    return newGame;
}


  

    const shuffleArray = (array) => {
        let currentIndex = array.length, temporaryValue, randomIndex;
        while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    };

    useEffect(() => {
        setGameData(data);
        setTimeElapsed(0);
        setUnplayedGames([...Array(data.length).keys()]);
        let gameIndex;

        const urlParams = new URLSearchParams(window.location.search);
        const gameId = urlParams.get('gameId');
        const archive = urlParams.get('archive');
        setGameId(gameId);

        const difficulty = urlParams.get('difficulty') || 'Medium';
        setDifficulty(difficulty);

        if(gameId){
            gameIndex = gameId;
            const mixedGame = mixGroups(gameIndex, difficulty);
            mixedGame.startingGroups = generateStartingGroups(mixedGame);
            setCurrentGame(mixedGame);
        } else {
            if(archive){
                setGameId(1);
                setIsArchive(true);
                gameIndex = generateIndexConnections(archive) % data.length;
            } else {
                gameIndex = generateIndexConnections() % data.length;
            }
            const selectedGame = data[gameIndex];
            selectedGame.startingGroups = generateStartingGroups(selectedGame);
            setCurrentGame(selectedGame);
        }

        setUnplayedGames(prevUnplayedGames => prevUnplayedGames.filter(index => index !== gameIndex));
        setSelectionStreak([]);
    }, [gameId]);

    const changeDifficulty = (newDifficulty) => {
        const randomIndex = Math.floor(Math.random() * unplayedGames.length);
        const nextGameIndex = unplayedGames[randomIndex];
        let final = nextGameIndex;
        if( gameId )
            final = gameId;
        window.location.href = window.location.origin + '?gameId=' + final + '&difficulty=' + newDifficulty;
    };

    const changeArchive = (newArchive) => {
        const formattedDate = `${newArchive.getMonth()+1}/${newArchive.getDate()}/${newArchive.getFullYear()}`;
        window.location.href = `${window.location.origin}?archive=${formattedDate}`;
    };

    function getDatesBetween(start, end) {
        let startDate = new Date(start);
        let endDate = new Date(end);
        let dates = [];
        while(startDate <= endDate) {
            dates.push(new Date(startDate));
            startDate.setDate(startDate.getDate() + 1);
        }
        return dates.reverse();
    }

    function generateIndexConnections(archive) {
        const D = 864e5;
        const e = new Date("9/18/2024");
        let t = false;
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        if( archive ) {
            let archiveDate = new Date(archive);
            archiveDate.setHours(0, 0, 0, 0);

            if( archiveDate.getTime() > today.getTime() ) {
                t = today - e.setHours(0, 0, 0, 0);
            } else {
                t = archiveDate - e.setHours(0, 0, 0, 0);
            }
        } else {
            t = today - e.setHours(0, 0, 0, 0);
        }

        let n = Math.round(t / D);
        return n < 0 ? Math.abs(n) : n;
    }

    const handleWordClick = word => {
        if (currentGuess.includes(word)) {
            setCurrentGuess(currentGuess.filter(guess => guess !== word));
        } else if (currentGuess.length < 4) {
            setCurrentGuess([...currentGuess, word]);
        } else {
            return;
        }
    };

    const submitGuess = () => {
      if (currentGuess.length !== 4) {
          setMessage('You need to select 4 items before submitting');
          return;
      }
  
      // Find the guessed group by comparing the currentGuess with the cards in each category
      const guessedGroup = currentGame.categories.find(
          (category) =>
              JSON.stringify(category.cards.map(card => card.content).sort()) === JSON.stringify(currentGuess.sort())
      );
  
      if (guessedGroup) {
          // Add the group to foundGroups
          setFoundGroups(prevGroups => ({
              ...prevGroups,
              [guessedGroup.title]: {
                  title: guessedGroup.title,  // Store the category title
                  cards: guessedGroup.cards.map(card => card.content),  // Store the card contents
              }
          }));
  
          // Remove the guessed cards from starting groups
          const newStartingGroups = currentGame.startingGroups
              .flat()
              .filter(word => !currentGuess.includes(word));
  
          setCurrentGame({
              ...currentGame,
              startingGroups: chunkArray(newStartingGroups, 4),
          });
  
          setMessage('Congratulations! You have found a group!');
          setAnimation('success');
          setAnimationWords(currentGuess);
          setTimeout(() => {
              setCurrentGuess([]);
              setAnimation(null);
              setAnimationWords([]);
          }, 1000);
      } else {
          setAnimation('error');
          setAnimationWords(currentGuess);
          setTimeout(() => {
              setAnimation(null);
              setAnimationWords([]);
          }, 1000);
          setMessage('Incorrect guess, please try again');
          setAttempts(prevAttempts => {
              if (prevAttempts - 1 === 0) {
                  setGameOver(true);
                  setMessage('Game Over! All attempts used up!');
              }
              return prevAttempts - 1;
          });
      }
  
      // Track the selection streak with word levels
      const wordLevels = currentGuess.map((word) => {
          const categoryName = currentGame.categories.find(
              category => category.cards.some(card => card.content === word)
          ).title;
  
          const level = currentGame.categories.find(
              category => category.cards.some(card => card.content === word)
          ).cards[0].position;
  
          return { word, level };
      });
  
      setSelectionStreak([...selectionStreak, wordLevels]);
  };
  

    const startNewGame = () => {
        if (unplayedGames.length === 0) {
            setMessage('No more games left!');
            return;
        }

        const randomIndex = Math.floor(Math.random() * 500);

        window.location.href = window.location.origin + '?gameId=' + randomIndex;
    };

    const restartGame = () => {
        window.location.reload();
    };

    const resetGuess = () => {
        setCurrentGuess([]);
    };

    const copyResultsToClipboard = () => {
        const resultsString = selectionStreak.map((guess, index) => 
            guess.map((wordObj) => `${levelEmojis[wordObj.level]}`).join('')
        ).join('');
        window.focus();
        navigator.clipboard.writeText('Connections-Sports.org - ' + resultsString).then(function(){
            alert('Copied to Clipboard, you can now share the result in social media.');
        });
    };

    const archiveDates = getDatesBetween("9/18/2024", new Date());

    return (
        <>
        {gameId && (
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
        )}
        
        
        {Object.entries(foundGroups).map(([categoryName, { title, cards }], index) => (
    <div
        className="game-group"
        key={categoryName}
        style={{ backgroundColor: levelColors[index % levelColors.length] }}
    >
        <h3 className="group-name">{title}</h3> {/* Use title instead of categoryName */}
        <div className="group-members">{cards.join(', ')}</div> {/* Correctly join the card contents */}
    </div>
))}


        <div className="top-g">
            <p className="p-text">{gameId && !isArchive && <strong>Connections Sports #{gameId} {difficulty && <>{difficulty}</>}</strong>}{!gameId || isArchive && <span>Create four groups of four.</span>}</p>
            <div className="game-time">Time: {`${Math.floor(timeElapsed / 60).toString().padStart(2, '0')}:${(timeElapsed % 60).toString().padStart(2, '0')}`}</div>
        </div>
        
        {currentGame && currentGame.startingGroups.map((group, groupIndex) => (
            <div className="game-board" key={groupIndex}>
                {group.map((word, wordIndex) => (
                    <button
                        className={`game-item ${currentGuess.includes(word) ? 'selected' : ''} ${animationWords.includes(word) ? `${animation}-animation` : ''} ${word.length === 8 ? 'size-8' : ''} ${word.length > 8 ? 'size-more' : ''}`}
                        key={wordIndex}
                        onClick={() => handleWordClick(word)}
                    >
                        {word || ' '}
                    </button>
                ))}
            </div>
        ))}

        {message && <div className="message">{message}</div>}
        <div className="game-attempts">Mistakes remaining: {attempts}</div>

        {gameOver ? (
            <>
                <button className="game-btn" onClick={restartGame}>Restart the same Game</button>
                <a 
                    className="game-btn" 
                    href="#" 
                    onClick={(e) => {
                        e.preventDefault();
                        startNewGame();
                    }}
                    role="button"
                    aria-pressed="false">Start New Game</a>
            </>
        ) : (
            <>
                {currentGame && Object.keys(foundGroups).length === Object.keys(currentGame.categories).length ? (
                    <>
                        <div className="congratulations">
                            <h2>Congratulations!</h2>
                            <p>You have found all groups!</p>
                            <div className="share">
                                {selectionStreak.map((guess, index) => (
                                    <div key={index} className="share-row">
                                        {guess.map((wordObj, i) => (
                                            <span key={i} style={{ backgroundColor: levelColors[wordObj.level] }}></span>
                                        ))}
                                    </div>
                                ))}
                            </div>
                            <button className="game-btn" style={{ backgroundColor:'#000000', color:'#fff', marginTop:'10px' }} onClick={copyResultsToClipboard}>Share Results</button>
                        </div>
                        <a 
                            className="game-btn" 
                            href="#" 
                            onClick={(e) => {
                                e.preventDefault();
                                startNewGame();
                            }}
                            role="button"
                            aria-pressed="false">Start New Game</a>
                    </>
                ) : (
                    <div className="btn-wrapper">
                        <button className="game-btn submit-btn" onClick={submitGuess}>Submit</button>
                        <button className="game-btn" onClick={resetGuess}>Deselect</button>
                        <a 
                            className="game-btn" 
                            href="#" 
                            onClick={(e) => {
                                e.preventDefault();
                                startNewGame();
                            }}
                            role="button"
                            aria-pressed="false">New Game</a>
                    </div>
                )}

                <div className="difficulty-wrapper">
                    <button onClick={() => changeDifficulty('Easy')} className={difficulty === 'Easy' ? 'selected' : ''}>Easy</button>
                    <button onClick={() => changeDifficulty('Medium')} className={difficulty === 'Medium' ? 'selected' : ''}>Medium</button>
                    <button onClick={() => changeDifficulty('Hard')} className={difficulty === 'Hard' ? 'selected' : ''}>Hard</button>
                </div>

                <div className="archive-wrapper">
                    <select onChange={e => changeArchive(new Date(e.target.value))}>
                        <option key="" value="">--Select an archive date--</option>
                        {archiveDates.map(date => {
                            const formattedDate = `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
                            return (
                                <option key={formattedDate} value={formattedDate}>
                                    {formattedDate}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </>
        )}
    </>
    );
}

function chunkArray(array, size) {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
}

export default Game;
